<template>
	<div class="fuwu">
		<header-view></header-view>
		<div class="top">Privacy Policy</div>
		<div class="content">This policy describes how HeardLy ("HeardLy", "we", "us") collects, receives,
			processes, uses, discloses, or shares your information. When you use our sites, products, or services or
			otherwise form a relationship with us, we may obtain data from you and the devices you are using to do
			so.<br><br>This policy is designed to provide transparency into our privacy practices and principles in a
			format you can navigate, read, and understand easily.<br><br>We commit to treating your personal information
			with care and respect and encourage you to contact us using the methods provided below should you have any
			questions or concerns about the use of your information.</div>
		<div class="title">The Scope of this Policy</div>
		<div class="content">
			This Policy describes the processing of personal information provided or collected on our sites,
			applications, products or services. In some instances, we may provide additional data privacy notices
			specific to certain products, practices, or regions. Those terms should be read in conjunction with this
			policy.<br><br>If you provide information to us on a third-party site or platform, the information you
			provide may be separately collected by the third-party site or platform. That third-party site or platform's
			privacy practices will govern the use of your information. Choices you make on that third-party platform
			will not apply to our use of the information we have collected through our sites, products, or
			services.<br><br>We acknowledge our obligations to all recipients of our products and services, including
			visitors to our website, our registered users and administrators of our products and services on behalf of
			our customers to collect, manage, process, and use their personal information ("Personal Data") in
			accordance with the laws and regulations of each of the countries in which we operate.
		</div>
		<div class="title">Information Collected or Processed</div>
		<div class="content">
			HeardLy may have collected or processed the categories of personal information that are identified below.
			We generally collect the information listed below to secure, improve, and provide our services to our
			customers.
			<li>Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier,
				Internet Protocol address, email address, account name, or other similar identifiers.</li>
			<li>Customer records information such as a name, credit card number, debit card number, or other payment
				information, or information about a purchased subscription.</li>
			<li>Internet or other electronic network activity information, including, but not limited to, browsing
				history, search history, and information regarding your interaction with an internet website
				application, or advertisement.</li>
			<li>Geolocation data.</li>
			<li>Professional or employment-related information.</li>
			<li>Education information.</li>
			<li>Inferences drawn from any of the information identified in this subdivision to reflect your preferences,
				characteristics, behavior, attitudes, abilities, and aptitudes.</li>
			<li>AppsFlyer is a mobile marketing analytics and attribution platform. It enables app marketers and
				developers to gain insights into the performance of their marketing campaigns, measure user acquisition,
				engagement, and retention for their apps, and make informed decisions to enhance their marketing
				strategies.
				For more information about how AppsFlyer collect and use your Personal Data, please refer to its privacy
				policies by the following link:https://www.appsflyer.com/legal/privacy-policy/</li>
		</div>
		<div class="title">Sources of Information</div>
		<div class="content">The sources from which personal information may have been collected or processed are the
			following: information that you provided to us, online tracking technologies, automatic data collection
			technologies, social media platforms and networks, business partners, service providers etc. The purpose of
			such collection or processing include: providing our products and services, communicating with you,
			administering programs, security and fraud prevention, legal obligations, core business functions, the job
			applicant and employment process, marketing and promotional purposes (which may include targeted
			advertising), analytics and personalization etc.</div>
		<div class="title">How We Disclose Information</div>
		<div class="content">In the process of providing services to you, we may disclose your Personal Data to our
			subsidiaries and affiliates, along with our service providers and other third-parties. We carefully select
			our service providers and business partners, especially with regard to the suitability of the technical and
			organizational measures taken by them. In each case, we take all reasonable measures to ensure that your
			Personal Data is protected (such as appropriate data processing agreements).<br><br>We may also disclose
			your Personal Data to service providers and third parties when necessary to comply with laws and
			regulations; respond to subpoenas and court orders; exercise our legal rights or defend against legal
			claims; investigate, prevent, or take action regarding illegal activities, suspected fraud, potential
			threats to our property or the physical safety of any person, or violations of our usage instructions and
			applicable terms and conditions; facilitate the financing or insuring of our business or a disposition of
			all or part of our business or assets, or as otherwise permitted or required by law. We may disclose
			anonymized or aggregated data at our discretion, in accordance with applicable laws.</div>
		<div class="title">Our Commitment to Protecting Information Belonging to Children</div>
		<div class="content">Our services are not intended for children under the age of 14. We do not knowingly
			collect information via our sites, products, and services from users under the age of 14. If you use our
			Services, you represent that you are at least the age of majority under the laws of the jurisdiction of your
			place of residence, or, as a minor, that you are at least 14 years old and that your legal representatives
			have provided their consent to use our services.<br><br>If you believe your child has provided us with
			personal information, please alert us at jm@heard.ly. If we learn that we have collected personal
			information from a minor without their legal representatives’ consent, we will promptly take appropriate
			steps to delete such information and terminate the minor’s account.</div>
		<div class="title">Cookies and Other Technologies</div>
		<div class="content">Cookies are identifiers we transfer to your browser or device that allow us to recognize
			your browser or device and tell us, for example, how and when pages and features in our services are visited
			and by how many people. You may be able to change the preferences on your browser or device to prevent or
			limit your device’s acceptance of cookies, but this may prevent you from taking advantage of some of our
			features. We recommend you keep cookies activated. We have broken up our cookies into categories to help you
			understand what they do:
			<li>Essential/Necessary: These cookies cannot be deactivated as they are required to use our services. For
				example, they are necessary to access and use our website and login to your account.</li>
			<li>Functionality, Preferences and Performance: These cookies track information about you to make using our
				website easier. For example, we may retain information such as your login status and diving
				preferences. These cookies also track how you interact with our website. For example, these cookies let
				us know which pages on our website load slowly or are not optimized for mobile use. We use this
				information to make improvements to our user experience.</li>
			<li>Marketing, Analytics and Statistics: These cookies help us create targeted advertising experiences on
				our websites.</li>
			You can manage your cookie preferences on our cookie banner that pops up when you first visit our website or
			via our Cookie Consent Manager.<br><br>
			HeardLy may also transfer your personal data to services providers of our Company, third parties such as App
			Store，Firebase， Facebook，Google Analytics.
			<li>App Store. App Store is a platform for downloading and distributing mobile apps for Apple devices, and
				for developers to monetize their apps.<br>
				For more information about how App Store collect and use your Personal Data, please refer to its privacy
				policies by the following link:https://www.apple.com/legal/privacy/</li>
			<li>Firebase. Firebase is a platform that allows developers to track user behavior and gather insights on
				their app's performance through a variety of metrics and reporting tools.<br>For more information about
				how Firebase collect and use your Personal Data, please refer to its privacy policies by the following
				link: https://policies.google.com/privacy</li>
			<li>Meta for Developers. Meta for Developers is a platform for developers to create and connect their apps
				with Facebook's platforms, such as Instagram, Messenger, and WhatsApp .<br>For more information about
				how Meta collect and use your Personal Data, please refer to its privacy policies by the following
				link:https://www.facebook.com/privacy/policy/</li>
			<li>Google Analytics is a web analytics service offered by Google that tracks and reports website
				traffic.<br>For more information about how Meta collect and use your Personal Data, please refer to its
				privacy policies by the following link：https://policies.google.com/privacy?</li>
		</div>
		<div class="title">Your Privacy Rights</div>
		<div class="content">If you are a resident of certain states or countries, you may have the right to be
			informed about the way your Personal Data is processed, withdraw your consent (if you have given consent to
			data processing activities), access your Personal Data, rectify, transfer your Personal Data (right to data
			portability), restrict the processing of, opt-out from certain types of processing (e.g., targeted
			advertising, the sale of Personal Data, limit the use and disclosure of sensitive information, and
			profiling, as defined by the applicable state law(s)), and delete your Personal Data (“right to be
			forgotten”). If you choose to exercise any of these options, we will not discriminate against you for making
			that decision.<br><br>You can exercise your privacy rights by contacting us at jm@heard.ly. When we
			receive a request to exercise a privacy right, we will rediv information we have collected about you (if
			any) with identifying information you may have provided us (such as a login, email address, mailing address,
			zip code, or transaction or order number) to authenticate your identity. If you use an authorized agent to
			submit a request, we may require the authorized agent to provide proof that you gave the agent signed
			permission to submit the request. We may also require you to directly confirm with us that you provided the
			authorized agent permission to submit the request.<br><br>If, and to the extent that, we process your
			Personal Data on the basis of a legitimate interest, in particular if we pursue our legitimate interest to
			conduct direct marketing or to apply profiling in connection with direct marketing, you have the right to
			object to such use of your Personal Data at any time. If you object to the processing of your Personal Data
			for direct marketing purposes, we will promptly stop processing your Personal Data for these purposes. In
			all other cases, we will carefully consider your objection and stop further use of the information in
			question, subject to compelling reasons for further processing that may take precedence over your interest
			in objecting, or if we need to use the information for the justification, exercise or defense of legal
			claims.<br><br>There may be situations where we cannot grant your request regarding your privacy rights. In
			such a situation, we will explain our reasoning to you and will honor your right to appeal where applicable.
		</div>
		<div class="title">Your Controls and Choices regarding Marketing Messaging</div>
		<div class="content">You can control how and when you want to receive marketing messaging, for example emails,
			from HeardLy by clicking on the “unsubscribe” link located at the bottom of our email communications to
			you, or by sending an email to jm@heard.ly. Please note that this will not affect your receipt of
			administrative emails (for example, emails about your account, transactions, or policy changes) for your
			registered account, and further, that the lawfulness of the processing that was based on that consent before
			it was withdrawn will not be affected.</div>
		<div class="content"><br>By using our products and services, you agree to join the mailing list of the
			MindHub
			Newsletters, provided by Cubo World Inc for Heardly. Please note that your email address will be shared
			with Cubo World Inc. via API integration solely for the purpose of delivering newsletters. Your personal
			and sensitive information will not be shared. You have the option to unsubscribe from the mailing list
			at any time by following the instructions provided in the newsletters.</div>
		<div class="title">How to Contact Us</div>
		<div class="content">If you have any questions about how we process your information, you can contact us
			at:<br><br>Email:
			jm@heard.ly<br><br>Our data protection officer can be reached (via an internal privacy coordinator)
			at email address. Data Protection Officer.</div>

		<div class="title">Final Clauses</div>
		<div class="content">HeardLy reserves the right to adjust this data privacy policy at any point in time to
			ensure that it is in line with the current legal requirements at all times, or in order to accommodate
			changes in the services offered, for example when new services are introduced or modifications are made on
			the website. In this case, the new data privacy policy applies to any later visit of this software or
			website.<br><br>This policy was last updated on: July 19, 2024</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		methods: {},
		mounted() {

		}
	}
</script>

<style lang="less" scoped>
	.fuwu {
		padding: 87px 15px 40px;

		.top {
			font-size: 28px;
			font-weight: 600;
			padding: 25px 0;
		}

		.title {
			font-size: 20px;
			margin: 20px 0;
		}

		.content {
			font-size: 13px;

			li {
				margin: 10px 0;
			}
		}
	}
</style>